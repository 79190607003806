// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap";

/** JQUERY **/
import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;

require('jquery-lazy');

/** BOOTSTRAP **/
let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))  
let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl)
})
var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})
/** SPREE **/
require('./spree/frontend/all');

/** PLUGINS **/
import Swiper from 'swiper/bundle';
window.Swiper = Swiper;
require('./jquery.fitvids');

import { Fancybox } from "@fancyapps/ui";
window.Fancybox = Fancybox;

/** CUSTOM **/
require('./main');
require('./shop');