const checkDistanceLeft = () => {
  let box = document.querySelector(".container");
  let style = box.currentStyle || window.getComputedStyle(box);
  $('.shop-list .shop-slider-holder').css('marginLeft',style.marginLeft)
}

const sliderCtaegory = new Swiper(".category-slider .swiper", {
  slidesPerView: "auto",
  grabCursor: true,
  speed: 3000,
  spaceBetween: 30,
  freeMode: true,
  loop: true,
  centeredSlides: true,
  autoplay: {
    delay: 0,
    disableOnInteraction: false
  }
});

const sliderProduct = new Swiper(".intro-product .swiper", {
  loop: true,
  pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
});

$( document ).ready(function() {
  checkDistanceLeft();
});

$(window).on("resize", function() {
  checkDistanceLeft();
});

$('#search-open').on("click", function() {
  $('#search-bar').fadeIn('fast');
});
$('#search-close').on("click", function() {
  $('#search-bar').fadeOut('fast');
});

$('.togglePassword').on('click', function(){
  $(this).toggleClass('mdi-eye mdi-eye-off');
  input = $($(this).data('toggle'));
  if (input.attr('type') == 'password'){
    input.attr('type', 'text');
  }
  else{
    input.attr('type', 'password');
  }
});