$(function () {
  $('.lazy').lazy({
    effect: "fadeIn",
    effectTime: 300,
    threshold: 0,
    afterLoad: function (element) {
      element.addClass('loaded');
    }
  });
});

$(function () {
  $('.lazy-header').lazy({
    effect: "fadeIn",
    effectTime: 600,
    threshold: 0,
    afterLoad: function (element) {
      element.addClass('loaded');
    }
  });
});

// $('.guests-slider').slick({
//   infinite: true,
//   centerMode: false,
//   // prevArrow: '.player-slider-prev',
//   // nextArrow: '.player-slider-next',
//   slidesToShow: 3.6,
//   slidesToScroll: 1,
//   responsive: [
//     {
//       breakpoint: 1200,
//       settings: {
//         slidesToShow: 2,
//       }
//     },
//   ]
// });

// var swiperNews = new Swiper(".latest-news .swiper", {
//   spaceBetween: 30,
//   effect: "creative",
//   creativeEffect: {
//     prev: {
//       shadow: false,
//       translate: [0, 0, -400],
//     },
//     next: {
//       translate: ["100%", 0, 0],
//     },
//   },
//   pagination: {
//     el: ".latest-news .swiper-pagination",
//     clickable: true,
//   },
//   speed: 1000,
//   autoplay: {
//     delay: 3500,
//     disableOnInteraction: false,
//   }
// });

// var caseHistoriesSwiper = new Swiper(".case-histories-slider .swiper", {
//   slidesPerView: 2.,
//   spaceBetween: 20,
//   autoHeight: true,
//   loop: true,
//   navigation: {
//     nextEl: ".case-histories-slider .custom-button-next",
//     prevEl: ".case-histories-slider .custom-button-prev",
//   },
//   breakpoints: {
//     0: {
//       slidesPerView: 1,
//     },
//     748: {
//       slidesPerView: 2,
//     },
//   }
// });

// var temaNewsSwiper = new Swiper(".tema-news-slider .swiper", {
//   slidesPerView: 2.5,
//   spaceBetween: 20,
//   autoHeight: true,
//   loop: true,
//   navigation: {
//     nextEl: ".tema-news-slider .custom-button-next",
//     prevEl: ".tema-news-slider .custom-button-prev",
//   },
//   breakpoints: {
//     0: {
//       slidesPerView: 1,
//     },
//     545: {
//       slidesPerView: 2,
//     },
//     1400: {
//       slidesPerView: 2.5,
//     },
//   }
// });

// var swiperExhib = new Swiper(".card-exhib-holder-v2 .swiper", {
//   slidesPerView: 2.,
//   spaceBetween: 20,
//   loop: true,
//   navigation: {
//     nextEl: ".card-exhib-holder-v2 .custom-button-next",
//     prevEl: ".card-exhib-holder-v2 .custom-button-prev",
//   },
//   breakpoints: {
//     0: {
//       slidesPerView: 1,
//     },
//     545: {
//       slidesPerView: 2,
//     },
//     1600: {
//       slidesPerView: 2.,
//     },
//   }
// });

// var swiperOff = new Swiper(".off-slider .swiper", {
//   slidesPerView: 3.2,
//   spaceBetween: 20,
//   centeredSlides: true,
//   loop: true,
//   breakpoints: {
//     0: {
//       slidesPerView: 1,
//       spaceBetween: 20,
//     },
//     576: {
//       slidesPerView: 2.5,
//     },
//     768: {
//       slidesPerView: 3.5,
//     },
//     1600: {
//       slidesPerView: 4.5,
//     },
//   }
// });

// var slideshow_home = new Swiper(".slideshow_home", {
//   centeredSlides: true,
//   effect: 'fade',
//   fadeEffect: {
//     crossFade: true
//   },
//   speed: 1000,
//   autoplay: {
//     delay: 3500,
//     disableOnInteraction: false,
//   },
//   pagination: {
//     el: ".slideshow_home_title .swiper-pagination",
//     clickable: true,
//   },
// });

// var swiperGuest = new Swiper("#guest_slider", {
//   slidesPerView: 1,
//   spaceBetween: 22,
//   loop: true,
//   pagination: {
//     el: "#guest_slider .swiper-pagination",
//     clickable: true,
//   },
//   breakpoints: {
//     445: {
//       slidesPerView: 2,
//     },
//     992: {
//       slidesPerView: 3,
//     },
//     1200: {
//       slidesPerView: 4,
//     },
//   }
// });

var swiperHeader = new Swiper(".header-slider .swiper", {
  loop: true,
  autoplay: {
    delay: 3500,
  },
  navigation: {
    nextEl: ".header-slider .swiper-button-next",
    prevEl: ".header-slider .swiper-button-prev",
  },
  pagination: {
    el: ".header-slider .swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    768: {
      pagination: false,
    }
  }
});

var collection = document.getElementsByClassName('shop_collection_slider');
Array.from(collection).forEach((el) => {
  let id = el.id;
  new Swiper(el, {
    slidesPerView: 1,
    spaceBetween: 22,
    loop: true,
    navigation: {
      nextEl: "#container_" + id + " .swiper-button-next",
      prevEl: "#container_" + id + " .swiper-button-prev",
    },
    breakpoints: {
      445: {
        slidesPerView: 2,
      },
      765: {
        slidesPerView: 2,
      },
      991: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: "auto",
      }
    }
  });
});

function checkMenu() {
  var scrolled = $(window).scrollTop();
  var preHeaderHeight = $('.pre-header').outerHeight();
  if (scrolled < preHeaderHeight) {
    $('.site-header').removeClass('menu-fixed');
  } else {
    $('.site-header').addClass('menu-fixed');
  }
  $('.menu-standby').css('top', preHeaderHeight);
  $('.menu-fixed').css('top', 0);
}

function shop_shop() {
  var windowWidth = $(window).width();

  if ($('.bg-shop .h1').length) {
    var offsetLeftNum = $('.bg-shop .h1').offset().left + 4;
    var offsetLeft = offsetLeftNum.toString();
    var offsetTop = 'px +.45em';
    if (windowWidth > 991) {
      var offsetTop = 'px .75em'
    }
    if (windowWidth > 1200) {
      var offsetTop = 'px .0em'
    }
    if (windowWidth > 1400) {
      var offsetTop = 'px 1em'
    }
    if (windowWidth > 1600) {
      var offsetTop = 'px 1.6em'
    }
    var offsetLeftTop = offsetLeft + offsetTop;
    $('.bg-shop').addClass('ok').css('background-position', offsetLeftTop);
    //console.log(offsetLeftTop);

    $('.shop-slider-holder').css('right', -offsetLeft + 16 + 'px');
    // $('#shop_slider .swiper-slide').css('width', $('.shop-home-container .slides-sizer').outerWidth() );
  }

}


function distance_nav() {
  if ($('body main').hasClass('distance-top')) {
    var h = $('.site-header').height();
    $('body main').css('padding-top', h + 'px');
  }
}

function secondary_menu() {
  //enable hamburger menu
  $('.hamburger-menu').on('click', () => {
    $('.hamburger-menu').toggleClass('secondary-menu-show');
    $('.site-header .secondary-menu').toggleClass('d-flex');
    $('.site-header .navbar-nav').toggleClass('text-green');
    if ($('.site-header').hasClass('secondary-header-primary '))
      $('.site-header .navbar-brand').toggleClass('text-dark');
    else
      $('.site-header .navbar-brand').toggleClass('text-white');
    $('html').toggleClass('overflow-y-hidden');
  });
}


$(document).ready(function () {
  checkMenu();
  shop_shop();
  distance_nav();
  secondary_menu();
  $(".videobox").fitVids();
});

$(window).on("scroll", function () {
  checkMenu();
  shop_shop();
});

$(window).on("resize", function () {
  checkMenu();
  shop_shop();
  distance_nav();
});